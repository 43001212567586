<template>
    <section class="content">
        <table class="table table-hover" ref="tablesuswa">
            <thead>
                <tr>
                    <th>NAMA</th>
                    <th>JK</th>
                    <th>BIRTH DATE</th>
                    <th>ALAMAT</th>
                    <th>NO. KTP</th>
                    <th>NO. HP</th>
                    <th>ASAL SEKOLAH</th>
                    <th>JURUSAN SEKOLAH</th>
                    <th>STATUS</th>
                </tr>
            </thead>
            <tbody @click="handleClick">
            </tbody>
        </table>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import datepicker from '@/components/Datepicker';
import Swal from "sweetalert2";

export default {
    name: 'Siswalist',
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            id: '',
            branch: ''
        }
    },
    components: {
        datepicker
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        console.log('load initial data', this.$route)
        this.id = this.$route.params.id
        this.branch = this.$route.params.branch
    },
    methods: {
        handleClick(e) {
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }
        },
    },
    mounted() {
        const e = this.$refs;
        var self = this;
        this.table = createTable(e.tablesuswa, {
            "title": "List Data",
            "roles": ["read"],
            "ajax": "/masterdata/siswa/perkelas/"+this.id,
            scrollX: true,
            "columns": [
                { "data": "display_name" },
                { "data": "gender" },
                { "data": "birth_date" },
                { "data": "addr_detail" },
                { "data": "no_ktp" },
                { "data": "no_hp" },
                { "data": "asal_sekolah" },
                { "data": "jurusan_sekolah" },
                { "data": "status" },
            ],
            filterBy: [0, 1, 2, 3, 4, 5, 6, 7],
            "rowCallback": function(row, data) {
                if (data.status == 1) {
                    $('td:eq(8)', row).html('<a class="badge badge-success" style="color:white">Active</a>');
                } else {
                    $('td:eq(8)', row).html('<a class="badge badge-success" style="color:white">Not Active</a>');
                }
            },
            buttonClick: (evt) => {
                if (evt.role == 'create') {
                    self.form = {};
                    self.method = 'POST';
                    self.errors = [];
                    self.formTitle = 'Tambah Siswa';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'update' && evt.data) {
                    self.form = evt.data;
                    self.method = 'PUT';
                    self.errors = [];
                    self.formTitle = 'Edit Siswa';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'delete' && evt.data) {
                    self.form = evt.data;
                    Swal.fire({
                        title: "Hapus data?",
                        icon: "question",
                        denyButtonText: '<i class="fa fa-times"></i> Hapus',
                        showCancelButton: true,
                        showDenyButton: true,
                        showConfirmButton: false,
                      }).then((result) => {
                        if (result.isDenied) {
                          authFetch('/masterdata/siswa/' + evt.data.id, {
                                method: 'DELETE',
                                body: 'id=' + evt.data.id
                            })
                            .then(res => {
                                return res.json();
                            })
                            .then(js => {
                                this.table.api().ajax.reload();
                            });
                        }
                      });
                }

            },
        });
    }
}
</script>
<style type="text/css">
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
</style>